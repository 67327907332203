html:hover {
  --antd-wave-shadow-color: transparent !important;
  --scroll-bar: 0;
}

*::-webkit-scrollbar {
  width: 0;
}

.ant-layout-header {
  height: 78px !important;
  background: #ffffff !important;
  border: 1px solid #e9eff4 !important;
  box-sizing: border-box !important;
  display: flex !important;
  justify-content: space-between;
  padding: 0 10px !important;
}

.ant-layout-sider,
.ant-layout-sider-light {
  max-width: 60px !important;
  min-width: 60px !important;
  width: 60px !important;
}

.iconNav {
  margin-left: 20px;
  height: 18px;
  display: flex;
}
.iconNav:hover svg {
  fill: #666666;
}
.selectedIcon:hover svg {
  fill: red;
}

.ant-table-thead > tr > th {
  color: white;
  background: #beeee8 !important;
}

.ant-table-tbody > tr > th {
  color: white;
  background: red !important;
}

.ant-input:focus {
  border-color: transparent !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgb(255, 255, 255) !important;
  box-shadow: 0 0 0 2px rgb(255, 255, 255) !important;
}

.ant-table-cell {
  text-align: center !important;
}
